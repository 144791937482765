import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './styles/main.scss';

import $ from 'jquery';
const jQuery = $;
window.jQuery = jQuery;
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/affix.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/alert.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/dropdown.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/tooltip.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/modal.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/button.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/popover.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/carousel.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/scrollspy.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/tab.js');
import balanceText from 'balance-text';

import './scripts/nav.js';
import './scripts/photoswipe.js';


/*eslint-env jquery */
(function ($) {
  'use strict';

  var blocks = $('.content .default-block');

  function init() {
    var width = blocks.last().outerWidth();
    console.log(width);
    blocks.css('height', width);

    // special block rules
    $('.content-block').css('min-height', width * 2);
  }

  init();
  $(window).resize(init);

  $('.ls-gallery').photogallery();

})(jQuery);

