const jQuery = require('jquery');
const PhotoSwipe = require('photoswipe');
const PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default');
/* eslint-env jquery */
/* eslint camelcase:1 */
/* global PhotoSwipe, PhotoSwipeUI_Default */
(function ($, PhotoSwipe) {
  'use strict';

  var PhotoGallery = function (element, options) {
    this.$element = $(element);
    this.options = $.extend({}, PhotoGallery.DEFAULTS, options);

    this.$items = this.$element.find(this.options.itemSelector);

    this.$pswpElement = $(this.options.pswpElement);
    this.pswpItems = this.$items.map(function () {
      // build items array
      var el = $(this);
      var size = el.data('size').split('x');
      return {
        src: el.attr('href'),
        w: size[0],
        h: size[1],
        el: el[0]
      };
    }).get();

    var self = this;
    // register handler
    this.$element.on('click', this.options.itemSelector, function (e) {
      e.preventDefault();
      self.show(this);
    });
  };

  PhotoGallery.DEFAULTS = {
    ui: PhotoSwipeUI_Default,
    pswpElement: '.pswp',
    itemSelector: '[itemprop="contentUrl"]'
  };

  PhotoGallery.prototype.show = function (element) {
    // define options (if needed)
    var options = {
      index: this.$items.index($(element))
    };

    // Initializes and opens PhotoSwipe
    var gallery = new PhotoSwipe(this.$pswpElement[0], this.options.ui, this.pswpItems, options);
    gallery.init();

  };

  // PLUGIN DEFINITION
  // ========================
  $.fn.photogallery = function (option) {
    return this.each(function () {
      var $this = $(this);
      var data = $this.data('zb.photogallery');
      var options = typeof option === 'object' && option;

      if (!data) {
        $this.data('zb.photogallery', (data = new PhotoGallery(this, options)));
      }
    });
  };

  $.fn.photogallery.Constructor = PhotoGallery;

})(jQuery, PhotoSwipe);
