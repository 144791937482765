const jQuery = require('jquery');


(function ($) {
  'use strict';
  var collapseOffset = 75;
  var collapseClass = 'top-nav-collapse';
  var fixedClass = 'navbar-fixed-top';

  var scrollLinks = $('.page-scroll');
  var head = $('.header');
  var navbar = $('.navbar-custom');

  var headHeight, headOuterHeight, navbarHeight;

  var collapsedState = false;
  var fixedState = false;

  var collapseTimeout;

  function init() {
    headHeight = head.height();
    headOuterHeight = head.outerHeight();
    navbarHeight = navbar.outerHeight();
  }

  function collapse(state) {
    if (state) {
      navbar.addClass(collapseClass);
    } else {
      navbar.removeClass(collapseClass);
    }
    if (collapseTimeout) {
      clearTimeout(collapseTimeout);
    }
    collapseTimeout = setTimeout(function () {
      navbarHeight = navbar.outerHeight();
    }, 333);
  }

  function fixate(state) {
    if (state) {
      navbar.addClass(fixedClass);
      navbar.next().css('margin-top', navbarHeight);
    } else {
      navbar.removeClass(fixedClass);
      navbar.next().css('margin-top', 0);
    }
  }

  function callOnChange(oldval, newval, fn) {
    if (oldval !== newval) {
      fn(newval);
    }
    return newval;
  }

  function scrollTrack() {
    var scrollTop = $(window).scrollTop();
    collapsedState = callOnChange(
      collapsedState,
      scrollTop > (headHeight - collapseOffset),
      collapse);
    fixedState = callOnChange(fixedState, scrollTop > headOuterHeight, fixate);
  }

  // jQuery for page scrolling feature - requires jQuery Easing plugin
  scrollLinks.bind('click', function (event) {
    var link = $(this);
    var navHeight = fixedState ? navbarHeight : navbar.outerHeight();
    var pos = $(link.attr('href')).offset().top - navHeight;
    link.blur();
    $('html, body').stop().animate({
      scrollTop: pos
    }, 1000, 'easeInOutExpo');
    event.preventDefault();
  });

  // Closes the Responsive Menu on Menu Item Click
  $('.navbar-collapse ul li a').click(function () {
    $('.navbar-toggle:visible').click();
  });

  init();
  $(window).scroll(scrollTrack);
  $(window).resize(init);
  scrollTrack();
})(jQuery);
